import React from 'react';
import * as styles from './ContentQuoteSplit.module.scss'
import * as style from '../../../styles/general.module.scss'
import PortableText from 'react-portable-text';
import { Link } from 'gatsby';



export default function ContentQuoteSplit ({ data }) {
    var ctaStyle = {}
    if(data.ctaLabel !==null){
        ctaStyle={display:"block"}
    }
    else{
        ctaStyle={display:"none"}
    }

    return (
        <div className={styles.contentQuoteSplit}>
            <div className={style.layout}>
                <div className={styles.contentWrap}>
                    <div className={styles.contentLeft}>
                        <h2>{data.title}</h2>

                        <div className={styles.textWrap}>
                            <PortableText content={data._rawContent}/>
                        </div>
                        <h4>"{data.verse}"</h4>
                        <h5>{data.verseReference}</h5>
                    
                    </div>
                    <div className={styles.contentRight}>
                        <div className={styles.quoteWrap}>
                            <h2>{data.quote}</h2>
                            <span>-{data.author}</span>
                        </div>
                    </div>
                   
                </div>
                <Link to={data.url}><span className={styles.button} style={ctaStyle}>{data.ctaLabel}</span></Link>
            </div>
        </div>
    )
}
