import { default as React } from "react"
import * as styles from './ResourceSearchResult.module.scss'
import {Link} from 'gatsby';
import Pill from "../Pill/Pill";
import BlueArrow from '../../images/blue-arrow.svg'

export default function ResourceSearchResult({hit}) {
  const inlineStyles = {
    background: `url(${hit.image})`,
    backgroundSize: `cover`,
    backgroundPosition: `center`
  }

  return (
    <div>
      <Link className={styles.resourceCard} to={hit.path}>
        <div className={styles.thumbnailContainer}>
          <div className={styles.thumbnail} style={inlineStyles}/>
        </div>

        <div className={styles.label}>
          <Pill label={hit.label}/>
        </div>

        <div className={styles.title}>
          {hit.title}
        </div>
        {(hit.tags) && (
          <div className={styles.tags}>
            {hit.tags.join(', ')}
          </div>
        )}
        <div className={styles.details}>
          <div className={styles.detailsLabel}>
            Details
          </div>
          <div className={styles.detailsArrowContainer}>
            <BlueArrow className={styles.blueArrow}/>
          </div>
        </div>
      </Link>
    </div>
  )
}
