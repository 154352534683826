import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import PageBuilder from '../components/PageBuilder'
import { Helmet } from 'react-helmet';

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: {eq: $id}) {
      id
      title
      slug {
        current
      }
      ...PageBuilder
    }
  }
`

const PageTemplate = props => {
  const {data, location} = props
  const {page} = data
  const {pageBuilder} = page
  if(page.title.includes("Home") ){
  return (
    <Layout>
       <Helmet>
       <meta name="viewport" content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;" />
        <meta charSet="utf-8" />
        <title>Cloudwalk Home</title>
        <meta name="referrer" content="origin" />
      </Helmet> 
      <PageBuilder sections={pageBuilder} location={location} />
    </Layout>
  )
}
else{
  return (
    <Layout>
       <Helmet>
       <meta name="viewport" content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;" />
        <meta charSet="utf-8" />
        <title>{page.title}-Cloudwalk</title>
        <meta name="referrer" content="origin" />
      </Helmet> 
      <PageBuilder sections={pageBuilder} location={location} />
    </Layout>
  )
}

}

export default PageTemplate
