import React from 'react';
import ResourceSearch from "../../ResourceSearch/ResourceSearch";


export default function ResourceArchive({data, location}) {
  return (
    <div className="wrapper">
      <ResourceSearch location={location}/>
    </div>
  )
}


