import React, {useState} from 'react';
import * as styles from './ResourceSearch.module.scss'
import algoliasearch from "algoliasearch/lite"
import qs from 'qs'
import {
  InstantSearch,
  Configure,
  Hits
} from 'react-instantsearch-dom'
import ResourceSearchResult from "../ResourceSearchResult/ResourceSearchResult";
import CustomSearchBox from "../CustomSearchBox/CustomSearchBox";
import CustomPagination from "../CustomPagintion/CustomPagination";
import PoweredByAlgolia from "../PoweredByAlgolia/PoweredByAlgolia";

const DEBOUNCE_TIME = 400;

const createURL = state => {
  const isDefaultRoute =
    !state.query &&
    state.page === 1;

  if (isDefaultRoute) {
    return '/resources/';
  }

  const queryParameters = {};

  if (state.query) {
    queryParameters.query = encodeURIComponent(state.query);
  }
  if (state.page !== 1) {
    queryParameters.page = state.page;
  }

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: 'repeat',
  });

  return `/resources/${queryString}`;
};

const searchStateToUrl = searchState =>
  searchState ? createURL(searchState) : '';

const urlToSearchState = location => {
  const { query = '', page = 1 } = qs.parse(
    location.search.slice(1)
  );

  return {
    query: decodeURIComponent(query),
    page,
  };
};

export default function ResourceSearch({location}) {
  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const [debouncedSetState, setDebouncedSetState] = useState(null);

  const isBrowser = typeof window !== "undefined"

  const onSearchStateChange = updatedSearchState => {
    clearTimeout(debouncedSetState);

    // We debounce so that we don't ruin performance for UX. Constant changing
    // of history state on every keystroke isn't great for UX.
    setDebouncedSetState(
      setTimeout(() => {
        if(isBrowser) {
          window.history.pushState(
            updatedSearchState,
            null,
            searchStateToUrl(updatedSearchState)
          );
        }
      }, DEBOUNCE_TIME)
    );

    setSearchState(updatedSearchState);
  };

  const searchClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY)

  return (
    <div>
      <InstantSearch searchClient={searchClient}
                     searchState={searchState}
                     onSearchStateChange={onSearchStateChange}
                     createURL={createURL}
                     indexName="Resources">
        <div className={styles.searchContainer}>
          <CustomSearchBox/>
        </div>

        <Configure hitsPerPage={12}/>

        <Hits className={styles.searchGrid} hitComponent={ResourceSearchResult}/>

        <div className={styles.paginationContainer}>
          <CustomPagination padding={2} showPrevious={true} showNext={true}/>
        </div>

        <div className={styles.poweredByContainer}>
          <PoweredByAlgolia className={styles.poweredByAlgolia}/>
        </div>

      </InstantSearch>
    </div>
  )
}
