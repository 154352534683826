import React from 'react';
import BlockquoteHero from "./pagebuilderComponents/BlockquoteHero/BlockquoteHero";
import ExperientialHero from "./ExperientialIntro/ExperientialHero";
import Faqs from './pagebuilderComponents/Faqs/Faqs';
import PageBanner from './pagebuilderComponents/pagebanner/pageBanner';
import ItemGrid from './pagebuilderComponents/itemGrid/itemGrid';
import TextAndPhotoMasthead from "./pagebuilderComponents/TextAndPhotoMasthead/TextAndPhotoMasthead";
import Textblock from './pagebuilderComponents/textblock/textblock';
import Events from './pagebuilderComponents/events/events';
import Event from './pagebuilderComponents/events/event';
import EventsArchive from './pagebuilderComponents/EventsArchive/EventsArchive';
import IconSection from './pagebuilderComponents/iconsection/iconSection';
import TeamSection from './pagebuilderComponents/teamSection/teamSection';
import ValuesSection from './pagebuilderComponents/valuesSection/valuesSection';
import PhotoAside from './pagebuilderComponents/photoAside/photoaside';
import BottomCta from './pagebuilderComponents/bottomCta/bottomCta';
import Updates from './pagebuilderComponents/getUpdates/getUpdates';
import MediaSplit from './pagebuilderComponents/mediaSplit/mediaSplit';
import Spacer from './pagebuilderComponents/spacer/spacer'
import Journey from './pagebuilderComponents/journey/journey';
import PersonList from './pagebuilderComponents/PersonList/PersonList';
import VideoHero from './pagebuilderComponents/videoHero/VideoHero';
import JourneyStages from './pagebuilderComponents/journeyStages/JourneyStages';
import CtaSection from './pagebuilderComponents/CtaSection/CtaSection';
import VideoCards from './pagebuilderComponents/VideoCards/VideoCards';
import ResourceArchive from './pagebuilderComponents/resourceArchive/resourceArchive';
import FileDl from './pagebuilderComponents/fileDl/FileDl';
import UpcomingDates from './pagebuilderComponents/UpcomingDates/UpcomingDates'
import HeroCta from './pagebuilderComponents/HeroCta/HeroCta';
import ContentQuoteSplit from './pagebuilderComponents/ContentQuoteSplit/ContentQuoteSplit';
import FullWidthImage from './pagebuilderComponents/FullWidthImage/FullWidthImage';

export default function PageBuilder(data) {
  const {sections, location} = data

  // Bootstrap Pagebuilder Components
  const pageBuilderComponents = {
    blockquoteHero: BlockquoteHero,
    experientialHero: ExperientialHero,
    faqs: Faqs,
    pageBanner: PageBanner,
    itemgrid: ItemGrid,
    textAndPhotoMasthead: TextAndPhotoMasthead,
    textBlock: Textblock,
    events: Events,
    iconSection: IconSection,
    teamSection: TeamSection,
    valuesSection: ValuesSection,
    photoaside: PhotoAside,
    bottomcta: BottomCta,
    updates: Updates,
    mediaSplit: MediaSplit,
    spacer: Spacer,
    journey: Journey,
    personList: PersonList,
    videoHero: VideoHero,
    journeyStages: JourneyStages,
    ctaSection: CtaSection,
    videoCards: VideoCards,
    resourceArchive: ResourceArchive,
    fileDl: FileDl,
    event: Event,
    eventsArchive: EventsArchive,
    upcomingDates: UpcomingDates,
    heroCta: HeroCta,
    contentQuoteSplit: ContentQuoteSplit,
    fullWidthImage: FullWidthImage
  }

  const pageSections = sections.map(section => {
    if(section) {
      // Get the type of Page Builder Section
      const sectionType = section._type

      // If we have a component for this page builder section, then render
      if(pageBuilderComponents.hasOwnProperty(sectionType)) {
        return React.createElement(pageBuilderComponents[sectionType], {
          key: section._key,
          data: section,
          location: location
        })
      }

      return null
    }

    // If we don't have a component for this, return null so that nothing renders
    return null
  })

  return (
    <div>
        {pageSections}
    </div>
  );
}


