import { default as React } from "react"
import * as styles from './Pill.module.scss'

export default function Pill({label}) {
  return (
    <div className={styles.pill}>
      {label}
    </div>
  )
}
