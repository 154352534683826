import React from 'react';
import * as styles from './FullWidthImage.module.scss'
import * as style from '../../../styles/general.module.scss'
import { GatsbyImage } from 'gatsby-plugin-image';

export default function FullWidthImage ({ data }) { 

    return (
        <div className={styles.fullWidthImage}>
            <div className={style.layout}>
                <div className={styles.imageWrapper}>
                    {data.image &&
                        <div>
                            <GatsbyImage
                                alt="image"
                                image={data.image.asset.gatsbyImageData}>
                            </GatsbyImage>
                        </div>
                    }
                </div>    
            </div>
        </div>
    )
}
