// extracted by mini-css-extract-plugin
export var blueArrow = "ResourceSearchResult-module--blueArrow--82d78";
export var details = "ResourceSearchResult-module--details--d260d";
export var detailsArrowContainer = "ResourceSearchResult-module--detailsArrowContainer--06b59";
export var detailsLabel = "ResourceSearchResult-module--detailsLabel--1f3fb";
export var label = "ResourceSearchResult-module--label--32f7c";
export var resourceCard = "ResourceSearchResult-module--resourceCard--3f193";
export var tags = "ResourceSearchResult-module--tags--a2425";
export var thumbnail = "ResourceSearchResult-module--thumbnail--b0c6d";
export var thumbnailContainer = "ResourceSearchResult-module--thumbnailContainer--b62b1";
export var title = "ResourceSearchResult-module--title--5f7e5";