import React from 'react';
import * as styles from './HeroCta.module.scss'
import { Link } from 'gatsby';
import PortableText from 'react-portable-text';
import { GatsbyImage } from 'gatsby-plugin-image';




export default function HeroCta ({ data }) {
    var background ={}
    var topLabelStyle = {}
    var ctaStyle = {}
    var ctaCheck = {}
    var secondHeader = {}
    if(data.backgroundColor === "aqua"){
        background ={background:"#316CD3"}
        secondHeader = {color:"#ffffff"}

        if(data.topLabel !==null){
            topLabelStyle = {background:"#F6F4F2"}
        }
        else{
            topLabelStyle = {display:"none"}
        }

        if(data.blueLabel !==null){
            ctaStyle ={display:"block", background:"#7CA7CE"}
        }
        else{
            ctaStyle ={display:"none"}
        }

        if(data.blacklabel !==null){
            ctaCheck ={display:"block"}
        }
        else{
            ctaCheck ={display:"none"}
        }
    }
    else if(data.backgroundColor === "pebble"){
        background ={background:"#F6F4F2"}
        secondHeader = {color:"#7CA7CE"}

        if(data.topLabel !==null){
            topLabelStyle = {background:"#ffffff"}
        }
        else{
            topLabelStyle = {display:"none"}
        }

        if(data.blueLabel !==null){
            ctaStyle ={display:"block"}
        }
        else{
            ctaStyle ={display:"none"}
        }
        if(data.blacklabel !==null){
            ctaCheck ={display:"block"}
        }
        else{
            ctaCheck ={display:"none"}
        }
    }
    else{
        background ={background:"#7CA7CE"}
        secondHeader = {color:"#ffffff"}

        if(data.topLabel !==null){
            topLabelStyle = {background:"#F6F4F2"}
        }
        else{
            topLabelStyle = {display:"none"}
        }

        if(data.blueLabel !==null){
            ctaStyle ={display:"block"}
        }
        else{
            ctaStyle ={display:"none"}
        }
        if(data.blacklabel !==null){
            ctaCheck ={display:"block"}
        }
        else{
            ctaCheck ={display:"none"}
        }
    }

    if(data.bgImage !== null){
    return (
        <div className={styles.heroCtaWrapper}>
          
            {data.bgImage &&
                <div className={styles.backgroundImage}>
                    <GatsbyImage
                        alt={data.bgImage}
                        image={data.bgImage.asset.gatsbyImageData}>
                    </GatsbyImage>
                </div>
                
            }    
                <div className={styles.heroContent}>
                    <span className={styles.topLabel} style={topLabelStyle}> {data.topLabel}</span>
                    <h1>{data.header}</h1>
                    <h2 style={secondHeader}>{data.secondHeader}</h2>
                    
                    <div className={styles.heroContentWrap}>
                        <PortableText content={data._rawContent}/>
                    </div>
                    
                    <div className={styles.buttonWrap}>
                        <Link to={data.blueLink}><span className={styles.blueButton} style={ctaStyle}>{data.blueLabel}</span></Link>
                        <Link to={data.blackLink}><span className={styles.blackButton} style={ctaCheck}>{data.blacklabel}</span></Link>
                    </div>

               </div>    
        </div>
    )
    }
    else{
        return (
            <div className={styles.heroCtaWrapper} >
            
                    <div className={styles.background} style={background}>
                    </div>
                    
                
                    <div className={styles.heroContent}>
                        <span className={styles.topLabel} style={topLabelStyle}>{data.topLabel}</span>
                        <h1>{data.header}</h1>
                        <h2 style={secondHeader}>{data.secondHeader}</h2>
                        
                        <div className={styles.heroContentWrap}>
                            <PortableText content={data._rawContent}/>
                        </div>
                        
                        <div className={styles.buttonWrap}>
                            <Link to={data.blueLink}><span className={styles.blueButton} style={ctaStyle}>{data.blueLabel}</span></Link>
                            <Link to={data.blackLink}><span className={styles.blackButton} style={ctaCheck}>{data.blacklabel}</span></Link>
                        </div>
    
                   </div>    
            </div>
        )  
    }
}
