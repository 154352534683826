import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import * as styles from './CustomSearchBox.module.scss'
import SearchIcon from '../../images/iconSearch.svg'

const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => (
  <form noValidate action="" role="search" className={styles.searchForm}>
    <input
      className={styles.searchInput}
      type="search"
      placeholder="Search"
      value={currentRefinement}
      onChange={event => refine(event.currentTarget.value)}
    />
    <div className={styles.searchIconContainer}>
      <SearchIcon/>
    </div>
    {isSearchStalled ? 'My search is stalled' : ''}
  </form>
);

const CustomSearchBox = connectSearchBox(SearchBox);

export default CustomSearchBox
