import React from 'react';
import * as styles from './EventsArchive.module.scss'
import * as style from '../../../styles/general.module.scss'
import {Link} from 'gatsby';
import {StaticQuery, graphql} from 'gatsby'


export default function EventsArchive({data}) {

  return (
    <section className={styles.eventsArchiveWrapper}>
      <div className={style.layout}>

        <StaticQuery query={graphql`
        query ResourceArchiveQuery {
            allSanityEventAndRetreat(sort: { fields: [startDate], order: ASC }) {
                                edges {
                                        node {
                                                slug {
                                                        current
                                                    }
                                                tags
                                                title
                                                postType
                                                startDate
                                                endDate
                                                image{
                                                        asset {
                                                            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1600)
                                                            }
                                                        }
                                                }
                                        }
                                    }
                                }
        
      `} render={data => {
          let date = new Date()
          var currentMonth = date.getMonth() + 1;
          var months = ["January", "February", "March", "April", "May", "June", "July",
            "August", "September", "October", "November", "December"];
          var monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul",
            "Aug", "Sep", "Oct", "Nov", "Dec"];
          var initialLoad = [];
          var secondLoad = [];
          var monthSections = []


          //set up a blank array for each month and date
          for (let y = 0; y < months.length; y++) {
            monthSections[y] = [];
          }

          //feed each array with a month heading
          for (let count = 0; count < 12; count++) {
            monthSections[count].push(<h1 className={styles.monthHeader}>{months[count]}</h1>)
          }

          //enable array inserting for sorting by date
          // eslint-disable-next-line no-extend-native
          Array.prototype.insert = function (index, item) {
            this.splice(index, 0, item);
          };

          //loop through for each month to sepearte each event into its proper month
          for (let x = 0; x < months.length; x++) {


            data.allSanityEventAndRetreat.edges.forEach(event => {

              const month = event.node.startDate;
              const endDate = event.node.endDate;
              var eventMonth = '';
              var eventDate = '';
              var endingDate = '';
              var endingMonth = '';
              var dateStyle = {};

              if (event.node.endDate !== null) {
                dateStyle = {display: "block"}
              } else {
                dateStyle = {display: "none"}
              }

              //start date
              if(month) {
                if (month.charAt(5) === '0') {
                  eventMonth = month.charAt(6)
                } else {
                  eventMonth = month.charAt(5) + month.charAt(6)
                }

                if (month.charAt(8) === '0') {
                  eventDate = month.charAt(9)
                } else {
                  eventDate = month.charAt(8) + month.charAt(9)
                }
              }


              //end date
              if (endDate !== null) {
                if (endDate.charAt(5) === '0') {
                  endingMonth = endDate.charAt(6)
                } else {
                  endingMonth = endDate.charAt(5) + month.charAt(6)
                }
                if (endDate.charAt(8) === '0') {
                  endingDate = endDate.charAt(9)
                } else {
                  endingDate = endDate.charAt(8) + endDate.charAt(9)
                }
              }

              if ((eventMonth - 1) === x) {

                monthSections[x].push(<Link to={event.node.slug.current}>
                  <div className={styles.eventCard}>
                    <div className={styles.cardDate}>
                      <span>{monthsShort[eventMonth - 1]} {eventDate}</span>
                      <span style={dateStyle}>-</span>
                      <span style={dateStyle}>{monthsShort[endingMonth - 1]} {endingDate}</span>
                    </div>

                    <div className={styles.cardContent}>
                      <h2>{event.node.postType}</h2>
                      <h3>{event.node.title}</h3>
                    </div>

                  </div>
                </Link>)

              }

            })

          }

          //loop through all months and decide which months are initially loaded and which are on load more
          for (let z = 0; z < monthSections.length; z++) {

            var monthCalc = ((currentMonth - z) - 1)
            if (monthCalc > 6 || (monthCalc < 1 && monthCalc > -6)) {
              if (monthSections[z].length > 1) {
                if ((z + 1) === currentMonth) {
                  initialLoad.insert(0, monthSections[z])
                } else if ((z + 1) > currentMonth) {
                  initialLoad.insert(((z + 1) - currentMonth), monthSections[z])
                } else {
                  initialLoad.push(
                    monthSections[z]
                  )
                }
              }
            } else {
              if (monthSections[z].length > 1) {
                secondLoad.push(
                  monthSections[z]
                )
              }
            }

          }

          return (<div className={styles.eventsListWrapper}>

            <div className={styles.initialLoad}>
              {initialLoad}
            </div>


            <div id="second-load" className={styles.secondLoad}>
              {secondLoad}
            </div>
            <span id="load-btn" className={styles.loadButton} onClick={loadMore} role="textbox" tabIndex={0}
                  onKeyDown={loadMore}>Load More</span>

          </div>)


        }
        }/>


      </div>
    </section>

  )
}

//Load More
const isBrowser = typeof window !== "undefined"


function loadMore() {
  if (isBrowser) {

    var extraEvents = document.getElementById("second-load");
    var btn = document.getElementById("load-btn");

    extraEvents.style.height = "initial";
    extraEvents.style.opacity = "1";
    btn.style.display = "none";

  }

}