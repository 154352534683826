import React from 'react';
import { connectPagination } from 'react-instantsearch-dom';
import * as styles from './CustomPagination.module.scss'
import {Link} from 'gatsby';
import {range} from "react-instantsearch-dom/dist/cjs/core/utils";
import NextArrow from '../../images/nextNav.svg'
import PreviousArrow from '../../images/prevNav.svg'

function calculateSize(padding, maxPages) {
  return Math.min(2 * padding + 1, maxPages);
}

function calculatePaddingLeft(currentPage, padding, maxPages, size) {
  if (currentPage <= padding) {
    return currentPage;
  }

  if (currentPage >= maxPages - padding) {
    return size - (maxPages - currentPage);
  }

  return padding + 1;
} // Retrieve the correct page range to populate the widget

function getPages(currentPage, maxPages, padding) {
  var size = calculateSize(padding, maxPages); // If the widget size is equal to the max number of pages, return the entire page range

  if (size === maxPages) return range({
    start: 1,
    end: maxPages + 1
  });

  var paddingLeft = calculatePaddingLeft(currentPage, padding, maxPages, size);
  var paddingRight = size - paddingLeft;
  var first = currentPage - paddingLeft;
  var last = currentPage + paddingRight;

  return range({
    start: first + 1,
    end: last + 1
  });
}

const Pagination = ({ currentRefinement, nbPages, refine, createURL, padding = 3, totalPages = Infinity, showFirst, showPrevious, showNext, showLast }) => {

  let items = []
  const maxPages = Math.min(nbPages, totalPages);
  const lastPage = maxPages;

  if (showFirst) {
    items.push({
      key: 'first',
      modifier: 'item--firstPage',
      disabled: currentRefinement === 1,
      label: 'First',
      value: 1,
      ariaLabel: 'First Page'
    });
  }

  if (showPrevious) {
    items.push({
      key: 'previous',
      modifier: styles.previousButton,
      disabled: currentRefinement === 1,
      label: (<PreviousArrow/>),
      value: currentRefinement - 1,
      ariaLabel: 'Previous page'
    });
  }

  items = items.concat(getPages(currentRefinement, maxPages, padding).map(function (value) {
    return {
      key: value,
      modifier: 'item--page',
      label: value,
      value: value,
      selected: value === currentRefinement,
      ariaLabel: `Page ${value}`
    };
  }));

  if (showNext ) {
    items.push({
      key: 'next',
      modifier: styles.nextButton,
      disabled: currentRefinement === lastPage || lastPage <= 1,
      label: (<NextArrow/>),
      value: currentRefinement + 1,
      ariaLabel: 'Next page'
    });
  }

  if (showLast) {
    items.push({
      key: 'last',
      modifier: 'item--lastPage',
      disabled: currentRefinement === lastPage || lastPage <= 1,
      label: 'Last',
      value: lastPage,
      ariaLabel: 'Last page'
    });
  }

  return (
    <div className={styles.pagination}>
      <ul className={styles.paginationList}>
        {items.map((_, index) => {
          const itemClasses = [styles.paginationLink]

          itemClasses.push(_.modifier)

          if(_.selected) {
            itemClasses.push(styles.paginationLinkActive)
          }

          if(_.disabled) {
            itemClasses.push(styles.paginationDisabled)
          }

          return (
            <li className={styles.paginationListItem} key={_.key}>
              <Link
                to={createURL(_.value)}
                aria-label={_.ariaLabel}
                className={itemClasses.join(' ')}
                onClick={event => {
                  event.preventDefault();
                  refine(_.value);
                }}
              >
                {_.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  )
};

const CustomPagination = connectPagination(Pagination);

export default CustomPagination
